import React, { useState } from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import "lazysizes"
import { MobileMenu, DesktopMenu } from "./menu"
import { Template } from "./Config/variable"
import "../styles/style.scss"
const Navigation = () => {
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      allListings ( filter: {operatorName: { regex: "/iKSANA Workspaces/" }}) {
        totalCount
        edges {
          node {
            monthPassAvailable
            dayPassAvailable
            hourPassAvailable
            officeSpaceType
            purposesList
            spaceAddress
            spaceGFName
            OriginalName
            spaceCity
            spaceId
            spaceImage
            spaceTitle
            spaceDesc
            spaceType
            subType
            priceDay
            priceHr
            priceMonth
            spaceDisplayName
            Facility
            slug
            hasCovidSafeBadge
            online
            Rating
          }
        }
      }
      allCities {
        edges {
          node {
            city
            cityPriority
            displayCity
            slug
          }
        }
      }
    }
    
  `)

  const [menuState, setmenuState] = useState("close")

  const MenuOpen = () => {
    setmenuState(prevState => {
      const menuState = prevState === "open" ? "close" : "open";
      console.log(menuState); // Log the new state
      return menuState;
    });
  };

  const [scrollState, setscrollState] = useState("top")
  const handleScroll = () => {
    if (window.scrollY === 0) {
      setscrollState("top")
    } else if (window.scrollY !== 0) {
      setscrollState("onmoving")
    }
  }
  if (typeof window !== "undefined") {
    window.addEventListener("scroll", handleScroll)
  }
  return (
    <div>
      <header className={"header"}>
        <div className="container-fluid p-4">
          <nav className="navbar navbar-expand-lg navbar-light">
            <Link className="navbar-brand logo center-logo" to="/">
              <img
                src={Template.spacePartnerLogo}
                alt={Template.spacePartnerName}
                width="150"
                height="70"
              />
            </Link>
            <button
              className="navbar-toggler"
              name="mobilenavigation"
              type="button"
              onClick={() => {
                MenuOpen("All")
              }}
            >
              <i className="fa fa-navicon"></i>
            </button>

            <DesktopMenu data={data} />
          </nav>
        </div>
      </header>
      <div className={"Nav mobile-only text-dark " + menuState}>
        <div className="text-right">
          <button
          className="MobileNavigation"
            name="MobileNavigation"
            type="button"
            onClick={() => {
              MenuOpen("All")
            }}
            style={{
              fontsize: "1.25rem",
              backgroundColor:"white"
            }}
          >
            <i className="fa fa-close" style={{color:"#f7ea83"}}></i>
          </button>
        </div>
        <MobileMenu data={data} />
        <div className="padding-20"></div>
        {/* <div className="text-center">
          <a
            href="https://www.gofloaters.com/app"
            target="_blank"
            className="listyourspace"
          >
            Login
          </a>
        </div> */}
        <div className="padding-20"></div>
        <br></br>
        <Link className="navbar-brand logo" to="/">
          <img
            data-src={Template.spacePartnerLogo}
            className="lazyload"
            alt="GoFloaters"
            width="250"
            height="70"
          />
        </Link>
      </div>
    </div>
  )
}

export default Navigation
